/* eslint-disable */
/* eslint-disable max-len */
<template >
<v-main v-if="onboardInProgress" >
  <v-layout text-center wrap>
      <v-flex>
          <span style="color: lime; font-size:20px" >
            <v-icon x-large color="amber">mdi-information-outline</v-icon>
            Service Provider Onboarding is in progress
           </span>
          <v-progress-circular
      :size="40"
      :width="7"
      color="amber"
      indeterminate
    ></v-progress-circular>
      </v-flex>
  </v-layout>
</v-main>
<v-main v-else>
  <v-card  class="mx-auto" color="grey darken-4" >
    <v-card-title class="justify-center">
      <h3 style="color: lime; text-align: right;">Service Provider Registration</h3>
    </v-card-title>
    <div class="text-center" style="color: yellow">
      <v-icon small color="amber">mdi-information</v-icon>
        If you refresh this page before submitting the form,
        you will loose the form data and session.
      </div>
    <form class="signup-form">
      <v-divider color="amber" class="my-4 mx-4"></v-divider>
      <div class="text-center" style="color: lime">
        <v-icon dark mr-5>mdi-account</v-icon>Service Provider Personal Information
      </div>
      <v-text-field
        v-model="firstName"
        label="First Name"
        :counter="10"
        :error-messages="firstNameErrors"
        dark
        required
        @input="$v.firstName.$touch()"
        @blur="$v.firstName.$touch()"
      ></v-text-field>
      <v-text-field
        v-model="lastName"
        label="Last Name"
        :counter="10"
        :error-messages="lastNameErrors"
        dark
        required
        @input="$v.lastName.$touch()"
        @blur="$v.lastName.$touch()"
      ></v-text-field>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field dark v-model="gregorianDate" label="Date of Birth" 
            readonly @click="openDatePicker" @input="$v.pickedDate.$touch()" 
            @blur="$v.pickedDate.$touch()" />
            <v-ethiopian-date-picker v-if="showDatePicker" 
            v-model="pickedDate"
            :min="dobMin"
            :max="dobMax"
            locale="am" 
            @change="convertDate" 
            @close-picker="closeDatePicker"/>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-text-field
        v-model="age"
        label="Age"
        dark
        required
        :error-messages="ageErrors"
        @input="$v.age.$touch()"
        @blur="$v.age.$touch()"
      ></v-text-field> -->
      <v-autocomplete
        v-model="gender"
        label="Gender"
        :items="genders"
        dark
        required
        :error-messages="genderErrors"
        @input="$v.gender.$touch()"
        @blur="$v.gender.$touch()"
      ></v-autocomplete>
      <v-text-field
        v-model="email"
        label="Email Adress"
        dark
        required
        :error-messages="emailErrors"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      ></v-text-field>
      <v-autocomplete
        v-model="educationalLevel"
        label="Educational Level"
        :items="educationalLevels"
        dark
        required
        :error-messages="educationalLevelErrors"
        @input="$v.educationalLevel.$touch()"
        @blur="$v.educationalLevel.$touch()"
      >
      </v-autocomplete>
      <div class="text-center" style="color: white">Profile Picture</div>
      <span v-if="$v.profilePictureImageData.$invalid" style="color: lime; font-size:12px" mb-4>
        <v-icon small color="amber">mdi-alert</v-icon>
        Ad a profile picture of the service provider (shoulder-up headshot)
      </span>
      <image-cropping
        :error-messages="profilePictureErrors"
        @load="$v.profilePictureImageData.$touch()"
        @input="$v.profilePictureImageData.$touch()"
        @blur="$v.profilePictureImageData.$touch()"
        formLabel="Service Provider Profile Picture"
        v-on:listingPicture="profilePictureImageData = $event"
      ></image-cropping>
      <div class="text-center" style="color: white">ID document Picture</div>
      <span v-if="$v.identificationDocImageData.$invalid" style="color: lime; font-size:12px" mb-4>
        <v-icon small color="amber">mdi-alert</v-icon>
        Add any form of picture identification document (Kebele, School, Work, Passport...)
      </span>
      <image-cropping
        :error-messages="idDocPictureErrors"
        @load="$v.identificationDocImageData.$touch()"
        @input="$v.identificationDocImageData.$touch()"
        @blur="$v.identificationDocImageData.$touch()"
        formLabel="Identification Document"
        v-on:listingPicture="identificationDocImageData = $event"
      ></image-cropping>
      <!-- <v-autocomplete
        prepend-icon="mdi-city"
        v-model="city"
        :items="cities"
        :label="`City  ${ !isEditing ? 'Enter your city' : ''}`"
        :error-messages="cityErrors"
        dark
        required
        @click="isEditing = !isEditing"
        @input="$v.city.$touch()"
      @blur="$v.city.$touch()"></v-autocomplete>-->
      <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
      <div class="text-center" style="color: lime">
        <v-icon dark mr-5>mdi-map-marker-plus</v-icon>Service Provider GoodayOn Avertisement
      </div>
      <v-textarea
        v-model="description"
        label="GoodayOn Ad Description"
        :counter="200"
        :error-messages="descriptionErrors"
        dark
        required
        @load="$v.description.$touch()"
        @input="$v.description.$touch()"
        @blur="$v.description.$touch()"
      ></v-textarea>
      <div class="text-center" style="color: white">Services</div>
      <span v-if="$v.services.$invalid" style="color: lime; font-size:12px" mb-4>
        <v-icon small color="amber">mdi-alert</v-icon>Select up to 3 services for Ad listing
      </span>
      <v-chip-group
        label="Services (maximum 3)"
        multiple
        max="3"
        column
        dark
        v-model="services"
        active-class="purple accent-3"
        :error-messages="serviceErrors"
        @load="$v.services.$touch()"
        @input="$v.services.$touch()"
        @blur="$v.services.$touch()"
        @click="$v.services.touch()"
      >
        <v-chip v-for="service in goodayServices" :key="service">{{ service }}</v-chip>
      </v-chip-group>
      <div class="text-center" style="color: white">Advertisement Picture</div>
      <span v-if="$v.adImageData.$invalid" style="color: lime; font-size:12px" mb-4>
        <v-icon small color="amber">mdi-alert</v-icon>
        Ad listing picture is required (add a picture to promote the service provider)
      </span>
      <image-cropping
        :error-messages="AdListingPictureErrors"
        @load="$v.adImageData.$touch()"
        @input="$v.adImageData.$touch()"
        @blur="$v.adImageData.$touch()"
        formLabel="Ad Listing picture"
        v-on:listingPicture="adImageData = $event"
      ></image-cropping>
      <div class="text-center" style="color: white">Service Provider Location</div>
      <span v-if="$v.providerLocation.$invalid" style="color: lime; font-size:12px">
        <v-icon small color="amber">mdi-alert</v-icon>
        Service Provider's approximate location is required
        (Enter a location name in Addis Ababa that is convenient for the service provider
         to work, and select from the drop down suggested by Google Maps)
      </span>
      <google-map
        :error-messages="providerLocationErrors"
        @load="$v.providerLocation.$touch()"
        @input="$v.providerLocation.$touch()"
        @blur="$v.providerLocation.$touch()"
        v-on:locationData="providerLocation = $event"
      />
      <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
      <div class="text-center" style="color: white">Training Interest & Emergency Contact</div>
      <span v-if="$v.identificationDocImageData.$invalid" style="color: lime; font-size:12px" mb-4>
        <v-icon small color="amber">mdi-alert</v-icon>
        Select service provider's interest of choise for upcoming/future trainings
      </span>
      <v-autocomplete
        v-model = "trainingPreference"
        :items="trainingOptions"
        label = "Select Training preference"
        dark
      >
      </v-autocomplete>
      <v-text-field
        v-model="emergencyContactName"
        label="Emergency Contact Name"
        :counter="20"
        :error-messages="emergencyContactNameErrors"
        dark
        required
        @input="$v.emergencyContactName.$touch()"
        @blur="$v.emergencyContactName.$touch()"
      ></v-text-field>
      <v-text-field
        v-model="emergencyContactPhone"
        label="Emergency Contact Phone"
        :counter="13"
        :error-messages="emergencyContactPhoneErrors"
        dark
        required
        @input="$v.emergencyContactPhone.$touch()"
        @blur="$v.emergencyContactPhone.$touch()"
      ></v-text-field>
      <v-divider color="amber" class="mt-10 mb-4 mx-4"></v-divider>
      <v-checkbox
        v-model="policyAgreement"
        label="Privacy Policy and Terms of Service Agreement"
        dark
        required
        :error-messages="policyAgreementErrors"
        @input="$v.policyAgreement.$touch()"
        @blur="$v.policyAgreement.$touch()"
      ></v-checkbox>
      <ul>
        <li><a href="https://gooday.io/#/privacy">Privacy Policy</a></li>
        <li><a href="https://sites.google.com/gooday.io/termsofuse">Terms of Service</a></li>
      </ul>
      <!-- <v-text-field
        v-if="showVerifyCode"
        label="Enter SMS code"
        v-model="verifyCode"
        dark></v-text-field>
        <p v-if="verifyError"
      style="color: red; font-size:12px">Invalid SMS token, request a token again!</p>-->

      <!-- <v-divider color="amber" class="my-4 mx-4"></v-divider>
      <div class="text-center" style="color: lime">
        <v-icon dark mr-5>mdi-account-group</v-icon> References
      </div> -->
      <!-- <span style="color: white">(Optional) Add 2 references ("የስራ ምስክር") that can provide
         positive testimony to the personal character, work ethic or
        quality of the service provider. Adding this information helps in getting
         more hiring opportunities for the service provider.</span>
      <div class="text-center" style="color: white" mt-4>Reference 1</div>
      <v-text-field
        v-model="ref1FirstName"
        label="First Name"
        :counter="10"
        dark
      ></v-text-field>
      <v-text-field
        v-model="ref1LastName"
        label="Last Name"
        :counter="10"
        dark
      ></v-text-field>
      <v-text-field
        v-model="ref1PhoneNumber"
        hint="0911121314"
        label="Phone Number"
        :counter="10"
        dark
      ></v-text-field>
      <div class="text-center" style="color: white">Reference 2</div>
      <v-text-field
        v-model="ref2FirstName"
        label="First Name"
        :counter="10"
        dark
      ></v-text-field>
      <v-text-field
        v-model="ref2LastName"
        label="Last Name"
        :counter="10"
        dark
      ></v-text-field>
      <v-text-field
        v-model="ref2PhoneNumber"
        label="Phone Number"
        hint="0911121314"
        :counter="10"
        dark
      ></v-text-field> -->
      <span v-if="!$v.$invalid" style="color: #D500F9; font-size:12px">
        <v-icon small color="#D500F9">mdi-information-outline</v-icon>
        Form is complete, Click Onboard to register service provider
      </span>
      <v-progress-linear
        v-if="!$v.$invalid"
        indeterminate
        rounded
        height="6"
        color="purple accent-3"
      ></v-progress-linear>
      <!-- <span
        v-if="phoneExists"
        style="color: lime; font-size:12px">
        <v-icon small color="amber">mdi-alert</v-icon>
        An account with this phone number exists
      </span>-->
    </form>
    <!-- <span
    v-if="routedNewUser && $v.$invalid"
    style="color: #FFC107; font-size:12px">
    <v-icon small color="amber">mdi-alert</v-icon>
    The phone number used for sign-in is new to the system, you need to register!
    </span>-->
    <v-card-actions class="justify-center">
      <v-btn @click="register" class="mr-10" :disabled="$v.$invalid">Onboard</v-btn>
      <v-btn @click="clear" class="ml-10">Clear Form</v-btn>
    </v-card-actions>
  </v-card>
</v-main>
</template>

<script>
// import { required,maxLength,email,minLength,numeric } from 'vuelidate/lib/validators';
import {
  required, maxLength, email, numeric, minLength,
} from 'vuelidate/lib/validators';
import firebase, { firestore } from 'firebase/app';
import VEthiopianDatePicker from 'vuetify-ethiopian-calendar';
// import EthiopianDate from 'ethiopian-date';
import { firebaseAuthMixins } from './mixins/firebaseAuth';
// import { ethiopiaCities } from '../data/cities';
// import { availableServices } from '../data/services';
import ImageCropping from './ImageCropping.vue';
import GMap from './GMap.vue';
import 'firebase/storage';

const ethiopianDate = require('ethiopian-date');

export default {
  components: {
    'image-cropping': ImageCropping,
    'google-map': GMap,
    'v-ethiopian-date-picker': VEthiopianDatePicker,
  },
  mixins: [
    firebaseAuthMixins,
  ],
  data() {
    return {
      onboardInProgress: false,
      goodayServices: null,
      firstName: '',
      lastName: '',
      //   phone: '',
      // age: 0,
      gender: '',
      //   confirmPhone: '',
      email: 'noemail@gooday.ai',
      //  confirmEmail: 'noemail@gooday.ai',
      //   city: '',
      profilePictureImageData: null,
      identificationDocImageData: null,
      adImageData: null,
      profilePicURL: null,
      idDocPicURL: null,
      adPicURL: null,
      description: '',
      services: [],
      providerLocation: null,
      policyAgreement: false,
      userOnboardData: {},
      adOnboardData: {},
      isEditing: false,
      ref1FirstName: '',
      ref1LastName: '',
      ref1PhoneNumber: '',
      ref2FirstName: '',
      ref2LastName: '',
      ref2PhoneNumber: '',
      educationalLevel: '',
      trainingPreference: '',
      emergencyContactName: '',
      emergencyContactPhone: '',
      showDatePicker: false,
      pickedDate: null,
      gregorianDate: null,
      activePicker: 'YEAR',
      dobMin: null,
      dobMax: null,
    };
  },

  validations: {
    firstName: {
      required,
      maxLen: maxLength(10),
    },
    lastName: {
      required,
      maxLen: maxLength(10),
    },
    // ref1FirstName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    // ref1LastName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    // ref2FirstName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    // ref2LastName: {
    //   required,
    //   maxLen: maxLength(10),
    // },
    pickedDate: {
      required,
    },
    // age: {
    //   required,
    //   numeric,
    //   minValue: minValue(14),
    //   maxValue: maxValue(69),
    // },
    gender: {
      required,
    },
    email: {
      required,
      email,
    },
    educationalLevel: {
      required,
    },
    emergencyContactName: {
      required,
      maxLen: maxLength(20),
    },
    emergencyContactPhone: {
      required,
      numeric,
      minLen: minLength(10),
      maxLen: maxLength(10),
    },
    // confirmEmail: {
    //   required,
    //   email,
    //   sameAs: sameAs('email'),
    // },
    // ref1PhoneNumber: {
    //   required,
    //   numeric,
    //   minLen: minLength(10),
    //   maxLen: maxLength(10),
    // },
    // ref2PhoneNumber: {
    //   required,
    //   numeric,
    //   minLen: minLength(10),
    //   maxLen: maxLength(10),
    // },
    // confirmPhone: {
    //   required,
    //   sameAs: sameAs('phone'),
    // },
    profilePictureImageData: {
      required,
    },
    identificationDocImageData: {
      required,
    },
    // city: {
    //   required,
    // },
    description: {
      required,
      maxLen: maxLength(200),
    },
    services: {
      required,
    },
    adImageData: {
      required,
    },
    providerLocation: {
      required,
    },
    trainingOptions: {
      required,
    },
    policyAgreement: {
      checked(val) {
        return val;
      },
    },
  },
  methods: {
    convertDate() {
    if (this.pickedDate) {
      const [ethiopianYear, ethiopianMonth, ethiopianDay] = this.pickedDate.split('-').map(Number);

      // Convert Ethiopian date to Gregorian
      const gd = ethiopianDate.toGregorian(ethiopianYear, ethiopianMonth, ethiopianDay);
      console.log('Gregorian Date Object:', gd);
      this.gregorianDate = gd.join('-');
      this.closeDatePicker();
    }
   },
   setdobMin() {
      const today = new Date();
      console.log(today);
      const [yyyy, mm, dd] = ethiopianDate.toEthiopian(
        today.getUTCFullYear(), today.getMonth(), today.getDate(),
      );
      this.dobMin = `${yyyy - 65}-${mm}-${dd}`;
    },
    setdobMax() {
      const today = new Date();
      const [yyyy, mm, dd] = ethiopianDate.toEthiopian(
        today.getUTCFullYear(), today.getMonth(), today.getDate(),
      );
      this.dobMax = `${yyyy - 14}-${mm}-${dd}`;
    },
    openDatePicker() {
      this.showDatePicker = true;
    },
    closeDatePicker() {
      this.showDatePicker = false;
    },
    getServices() {
      const db = firebase.firestore();
      db.collection('services')
        .doc('gooday_classified_services')
        .get()
        .then((servicesSnapshot) => {
          const serviceList = [];
          servicesSnapshot.data().services
            .forEach((c) => { serviceList.push(...c.services); });
          this.goodayServices = [...new Set(serviceList)].sort();
        })
        .catch((error) => console.log(error));
    },
    clear() {
      this.firstName = '';
      this.lastName = '';
      this.phone = '';
      this.pickedDate = null;
      // this.age = 0;
      this.gender = '';
      this.educationalLevel = '';
      this.confirmPhone = '';
      this.profilePictureImageData = null;
      this.identificationDocImageData = null;
      this.adImageData = null;
      this.description = '';
      this.services = [];
      this.emergencyContactName = '';
      this.emergencyContactPhone = '';
      this.providerLocation = null;
      this.policyAgreement = false;
    },
    register() {
      const references = [];
      // quit if onboarding badge number is null
      if (this.$store.getters['auth/badgeNumber'] == null) return;
      this.onboardInProgress = true;
      const imageName = 'userData.jpeg';
      //   uploading profile picture
      this.profilePictureImageData.toBlob(async (imageBlob) => {
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef.child(`profile/${firebase.auth().currentUser.uid}/${imageName}`).put(imageBlob);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
          // Get task progress, including the number of bytes uploaded and
        //   the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // eslint-disable-next-line no-console
          console.log(`Upload is ${progress}% done`);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // eslint-disable-next-line no-console
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // eslint-disable-next-line no-console
              console.log('Upload is running');
              break;
            case firebase.storage.TaskState.ERROR:
              console.log('Error occured uploading image data');
              break;
            case firebase.storage.TaskState.SUCCESS:
              console.log('Upload is successful');
              break;
            default:
              console.log('something weird happened');
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // eslint-disable-next-line no-console
              console.log('user doesnt have permission');
              break;

            case 'storage/canceled':
              // User canceled the upload
              // eslint-disable-next-line no-console
              console.log('upload is canceled');
              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              // eslint-disable-next-line no-console
              console.log('unknown error');
              break;
            default:
              console.log('unexpected error occured');
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL()
            .then((downloadURL) => {
            // eslint-disable-next-line no-console
            // console.log('File available at', downloadURL);
              this.profilePicURL = downloadURL;

              // uploading identification doc
              this.identificationDocImageData.toBlob(async (imageBlob2) => {
                const uploadTask2 = storageRef.child(`identification/${firebase.auth().currentUser.uid}/${imageName}`).put(imageBlob2);
                uploadTask2.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and
                  //   the total number of bytes to be uploaded
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  // eslint-disable-next-line no-console
                  console.log(`Upload is ${progress}% done`);
                  switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                      // eslint-disable-next-line no-console
                      console.log('Upload is paused');
                      break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                      // eslint-disable-next-line no-console
                      console.log('Upload is running');
                      break;
                    case firebase.storage.TaskState.ERROR:
                      console.log('Error occured uploading image data');
                      break;
                    case firebase.storage.TaskState.SUCCESS:
                      console.log('Upload is successful');
                      break;
                    default:
                      console.log('something weird happened');
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case 'storage/unauthorized':
                      // User doesn't have permission to access the object
                      // eslint-disable-next-line no-console
                      console.log('user doesnt have permission');
                      break;

                    case 'storage/canceled':
                      // User canceled the upload
                      // eslint-disable-next-line no-console
                      console.log('upload is canceled');
                      break;

                    case 'storage/unknown':
                      // Unknown error occurred, inspect error.serverResponse
                      // eslint-disable-next-line no-console
                      console.log('unknown error');
                      break;
                    default:
                      console.log('unexpected error occured');
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  uploadTask2.snapshot.ref.getDownloadURL()
                    .then((downloadURL2) => {
                      // eslint-disable-next-line no-console
                      // console.log('File available at', downloadURL2);
                      this.idDocPicURL = downloadURL2;

                      // uploading ad picture
                      this.adImageData.toBlob(async (imageBlob3) => {
                        const uploadTask3 = storageRef.child(`gooday/${firebase.auth().currentUser.uid}/${imageName}`).put(imageBlob3);
                        uploadTask3.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                          // Get task progress, including the number of bytes uploaded and
                          //   the total number of bytes to be uploaded
                          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          // eslint-disable-next-line no-console
                          console.log(`Upload is ${progress}% done`);
                          switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                              // eslint-disable-next-line no-console
                              console.log('Upload is paused');
                              break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                              // eslint-disable-next-line no-console
                              console.log('Upload is running');
                              break;
                            case firebase.storage.TaskState.ERROR:
                              console.log('Error occured uploading image data');
                              break;
                            case firebase.storage.TaskState.SUCCESS:
                              console.log('Upload is successful');
                              break;
                            default:
                              console.log('something weird happened');
                              break;
                          }
                        },
                        (error) => {
                          // A full list of error codes is available at
                          // https://firebase.google.com/docs/storage/web/handle-errors
                          switch (error.code) {
                            case 'storage/unauthorized':
                              // User doesn't have permission to access the object
                              // eslint-disable-next-line no-console
                              console.log('user doesnt have permission');
                              break;

                            case 'storage/canceled':
                              // User canceled the upload
                              // eslint-disable-next-line no-console
                              console.log('upload is canceled');
                              break;

                            case 'storage/unknown':
                              // Unknown error occurred, inspect error.serverResponse
                              // eslint-disable-next-line no-console
                              console.log('unknown error');
                              break;
                            default:
                              console.log('unexpected error occured');
                              break;
                          }
                        },
                        () => {
                          // Upload completed successfully, now we can get the download URL
                          uploadTask3.snapshot.ref.getDownloadURL()
                            .then((downloadURL3) => {
                              // eslint-disable-next-line no-console
                              // console.log('File available at', downloadURL3);
                              this.adPicURL = downloadURL3;
                              this.userOnboardData = {
                                age: parseInt(this.age, 10),
                                dateOfBirth: this.dateOfBirth,
                                createdAt: firestore.Timestamp.now(),
                                email: this.email,
                                firstName: this.firstName,
                                lastName: this.lastName,
                                geoLoc: {
                                  geohash: this.providerLocation.geohash,
                                  // eslint-disable-next-line max-len
                                  geopoint: this.serviceLocation,
                                },
                                identificationPic: this.idDocPicURL,
                                lastUpdate: firestore.Timestamp.now(),
                                phoneNumber: firebase.auth().currentUser.phoneNumber,
                                policyAgree: this.policyAgreement,
                                profilePic: this.profilePicURL,
                                rating: 5,
                                reportingCount: 0,
                                reviewingCount: 0,
                                serviceProvider: true,
                                sex: this.gender,
                                educationalLevel: this.educationalLevel,
                                emergencyContactName: this.emergencyContactName,
                                emergencyContactPhone: this.emergencyContactPhone,
                                verifiedUser: false,
                                onboardingAgentBadgeNumber: this.$store.getters['auth/badgeNumber'],
                                // city: this.city,
                              };
                              this.adOnboardData = {
                                approvedUser: true,
                                createdAt: firestore.Timestamp.now(),
                                firstName: this.firstName,
                                lastName: this.lastName,
                                educationalLevel: this.educationalLevel,
                                geoLoc: {
                                  geohash: this.providerLocation.geohash,
                                  // eslint-disable-next-line max-len
                                  geopoint: this.serviceLocation,
                                },
                                lastUpdate: firestore.Timestamp.now(),
                                phoneNumber: firebase.auth().currentUser.phoneNumber,
                                pictureURL: this.adPicURL,
                                policyAgree: true,
                                profilePicURL: this.profilePicURL,
                                services: this.providerServices,
                                summary: this.description,
                              };
                              //   console.log('userData for onboarding', this.userOnboardData);
                              //   console.log('adData for onboarding', this.adOnboardData);
                              const db = firebase.firestore();
                              db.collection('users').doc(firebase.auth().currentUser.uid).set(this.userOnboardData)
                                .then((docRef) => {
                                  // eslint-disable-next-line no-console
                                  console.log('user data written with ID: ', docRef);
                                  // add ad doc to goodays collection
                                  db.collection('goodays').doc(firebase.auth().currentUser.uid).set(this.adOnboardData)
                                    .then(() => {
                                      const dateNow = new Date();
                                      // console.log(dateNow);
                                      dateNow.setDate(dateNow.getDate() + 180);
                                      db.collection('billing').doc(firebase.auth().currentUser.uid).set({
                                        paidThrough: firestore.Timestamp.fromDate(
                                          dateNow,
                                        ),
                                      })
                                        .then(() => {
                                          // console.log(billingref);
                                          // eslint-disable-next-line no-console
                                          // console.log('Document written with ID: ', ref);
                                          if (this.ref1FirstName !== '' && this.ref1PhoneNumber !== '') {
                                            references.push(
                                              {
                                                firstName: this.ref1FirstName,
                                                lastName: this.ref1LastName,
                                                phoneNumber: this.ref1PhoneNumber,
                                              },
                                            );
                                          }
                                          if (this.ref2FirstName !== '' && this.ref2PhoneNumber !== '') {
                                            references.push(
                                              {
                                                firstName: this.ref2FirstName,
                                                lastName: this.ref2LastName,
                                                phoneNumber: this.ref2PhoneNumber,
                                              },
                                            );
                                          }
                                          if (references.length !== 0) {
                                            db.collection('gdoAdmReferences').doc(firebase.auth().currentUser.uid).set({
                                              references: [
                                                {
                                                  firstName: this.ref1FirstName,
                                                  lastName: this.ref1LastName,
                                                  phoneNumber: this.ref1PhoneNumber,
                                                },
                                                {
                                                  firstName: this.ref2FirstName,
                                                  lastName: this.ref2LastName,
                                                  phoneNumber: this.ref2PhoneNumber,
                                                },
                                              ],
                                            }).then((refr) => {
                                              console.log(refr);
                                              this.$store.dispatch('auth/signOut');
                                              // eslint-disable-next-line no-alert
                                              alert('Provider is successfully onboarded, the service provider can now be found on GoodayOn App');
                                            })
                                              .catch((error) => {
                                                console.log(error);
                                              });
                                          } else {
                                            this.$store.dispatch('auth/signOut');
                                            // eslint-disable-next-line no-alert
                                            alert('Provider is successfully onboarded, the service provider can now be found on GoodayOn App');
                                          }
                                        })
                                        .catch((error) => {
                                          console.log(error);
                                        });
                                    })
                                    .catch((error) => {
                                      // eslint-disable-next-line no-console
                                      console.log(error);
                                    });
                                })
                                .catch((error) => {
                                  // eslint-disable-next-line no-console
                                  console.log(error);
                                });
                            });
                        });
                      });
                    });
                });
              });
            });
        });
      });
    },
  },

  computed: {
    age() {
      const dob = Date.parse(this.gregorianDate);
      const ageDate = new Date(Date.now() - dob);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    dateOfBirth() {
      const dob = Date.parse(this.gregorianDate);
      return firestore.Timestamp.fromMillis(dob);
    },
    providerServices() {
      const ps = [];
      this.services.forEach((element) => {
        ps.push(this.goodayServices[element]);
      });
      return ps;
    },
    serviceLocation() {
      const locationPoint = new firebase.firestore.GeoPoint(this.providerLocation.lat,
        this.providerLocation.lng);
      return locationPoint;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      /* eslint-disable no-unused-expressions */
      !this.$v.firstName.maxLen && errors.push('Name must be at most 10 characters long');
      !this.$v.firstName.required && errors.push('Name is Required');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.maxLen && errors.push('Name must be at most 10 characters long');
      !this.$v.lastName.required && errors.push('Name is Required');
      return errors;
    },
    emergencyContactNameErrors() {
      const errors = [];
      if (!this.$v.emergencyContactName.$dirty) return errors;
      !this.$v.lastName.maxLen && errors.push('Emergency Contact name must be at most 20 characters long');
      !this.$v.lastName.required && errors.push('Emergenecy contact name is Required');
      return errors;
    },
    emergencyContactPhoneErrors() {
      const errors = [];
      if (!this.$v.emergencyContactPhone.$dirty) return errors;
      !this.$v.emergencyContactPhone.maxLen && errors.push('Phone number should be 10 digits long');
      !this.$v.emergencyContactPhone.minLen && errors.push('Phone number should be 10 digits long');
      !this.$v.emergencyContactPhone.required && errors.push('phone number is Required');
      !this.$v.emergencyContactPhone.numeric && errors.push('Only numeric digits allowed, example 0911121314');
      return errors;
    },
    // ref1FirstNameErrors() {
    //   const errors = [];
    //   if (!this.$v.ref1FirstName.$dirty) return errors;
    //   /* eslint-disable no-unused-expressions */
    // eslint-disable-next-line max-len
    //   !this.$v.ref1FirstName.maxLen && errors.push('Reference\'s Name must be at most 10 characters long');
    //   !this.$v.ref1FirstName.required && errors.push('Reference\'s Name is Required');
    //   return errors;
    // },
    // ref1LastNameErrors() {
    //   const errors = [];
    //   if (!this.$v.ref1LastName.$dirty) return errors;
    // eslint-disable-next-line max-len
    //   !this.$v.ref1LastName.maxLen && errors.push('Reference\'s Name must be at most 10 characters long');
    //   !this.$v.ref1LastName.required && errors.push('Reference\'s Name is Required');
    //   return errors;
    // },
    // ref2FirstNameErrors() {
    //   const errors = [];
    //   if (!this.$v.ref2FirstName.$dirty) return errors;
    //   /* eslint-disable no-unused-expressions */
    // eslint-disable-next-line max-len
    //   !this.$v.ref2FirstName.maxLen && errors.push('Reference\'s Name must be at most 10 characters long');
    //   !this.$v.ref2FirstName.required && errors.push('Reference\'s Name is Required');
    //   return errors;
    // },
    // ref2LastNameErrors() {
    //   const errors = [];
    //   if (!this.$v.ref2LastName.$dirty) return errors;
    // eslint-disable-next-line max-len
    //   !this.$v.ref2LastName.maxLen && errors.push('Reference\'s Name must be at most 10 characters long');
    //   !this.$v.ref2LastName.required && errors.push('Reference\'s Name is Required');
    //   return errors;
    // },
    // ref1PhoneNumberErrors() {
    //   const errors = [];
    //   if (!this.$v.ref1PhoneNumber.$dirty) return errors;
    // eslint-disable-next-line max-len
    //   !this.$v.ref1PhoneNumber.maxLen && errors.push('Reference\'s Phone number should be 10 digits long');
    // eslint-disable-next-line max-len
    //   !this.$v.ref1PhoneNumber.minLen && errors.push('Reference\'s Phone number should be 10 digits long');
    //   !this.$v.ref1PhoneNumber.required && errors.push('Reference 1 phone number is Required');
    // eslint-disable-next-line max-len
    //   !this.$v.ref1PhoneNumber.numeric && errors.push('Only numeric digits allowed, example 0911121314');
    //   return errors;
    // },
    // ref2PhoneNumberErrors() {
    //   const errors = [];
    //   if (!this.$v.ref2PhoneNumber.$dirty) return errors;
    // eslint-disable-next-line max-len
    //   !this.$v.ref2PhoneNumber.maxLen && errors.push('Reference\'s Phone number should be 10 digits long');
    // eslint-disable-next-line max-len
    //   !this.$v.ref2PhoneNumber.minLen && errors.push('Reference\'s Phone number should be 10 digits long');
    //   !this.$v.ref2PhoneNumber.required && errors.push('Reference 2 phone number is Required');
    // eslint-disable-next-line max-len
    //   !this.$v.ref2PhoneNumber.numeric && errors.push('Only numeric digits allowed, example 0911121314');
    //   return errors;
    // },
    pickedDateErrors() {
      const errors = [];
      if (!this.$v.pickedDate.$dirty) return errors;
      !this.$v.pickedDate.required && errors.push('Date of Birth is required');
      return errors;
    },
    // ageErrors() {
    //   const errors = [];
    //   if (!this.$v.age.$dirty) return errors;
    //   !this.$v.age.maxValue && errors.push('Maximum allowed age is 69');
    //   !this.$v.age.minValue && errors.push('Minimum allowed age is 14');
    //   !this.$v.age.required && errors.push('Age is required');
    //   return errors;
    // },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be a valid E-mail address');
      !this.$v.email.required && errors.push('E-mail is Required');
      return errors;
    },
    policyAgreementErrors() {
      const errors = [];
      if (!this.$v.policyAgreement.$dirty) return errors;
      !this.$v.policyAgreement.checked && errors.push('You must agree to policy to continue!');
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push('Gender is required field');
      return errors;
    },
    educationalLevelErrors() {
      const errors = [];
      if (!this.$v.educationalLevel.$dirty) return errors;
      !this.$v.educationalLevel.required && errors.push('Education level is required');
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      /* eslint-disable no-unused-expressions */
      !this.$v.description.maxLen && errors.push('Ad Description can be at most 200 characters long');
      !this.$v.description.required && errors.push('Ad Description is Required');
      return errors;
    },
    providerLocationErrors() {
      const errors = [];
      if (!this.$v.providerLocation.$dirty) return errors;
      !this.$v.providerLocation.required && errors.push('Drop pin on the map and select the provider\'s approximate service area');
      (this.providerLocation == null) && errors.push('Drop pin on the map and select the provider\'s approximate service area');
      return errors;
    },
    AdListingPictureErrors() {
      const errors = [];
      if (!this.$v.adImageData.$dirty) return errors;
      !this.$v.adImageData.required && errors.push('Ad picture is required');
      return errors;
    },
    serviceErrors() {
      const errors = [];
      if (!this.$v.services.$dirty) return errors;
      !this.$v.services.required && errors.push('Select atleast one service');
      return errors;
    },
    profilePictureErrors() {
      const errors = [];
      if (!this.$v.profilePictureImageData.$dirty) return errors;
      !this.$v.profilePictureImageData.required && errors.push('Profile picture is required');
      return errors;
    },
    idDocPictureErrors() {
      const errors = [];
      if (!this.$v.identificationDocImageData.$dirty) return errors;
      !this.$v.identificationDocImageData.required && errors.push('Identification document picture is required');
      return errors;
    },
    formClear() {
      return !this.$v.$invalid;
    },
    routedNewUser() {
      return this.$store.getters['auth/routedNewUser'];
    },
    genders() {
      return ['Female', 'Male'];
    },
    educationalLevels() {
      return [
        'Primary School (1-8)',
        'High School (9-10)',
        'Preparatory (11-12)',
        'Certificate',
        'Diploma',
        'Degree',
        'None',
      ];
    },
    trainingOptions() {
      return [
        'Culinary',
        'House Keeping',
        'Home Appliance Repair',
        'Plumber',
        'Gypsum Work',
        'Painting',
        'Office Machine Repair',
      ];
    },
    // goodayServices() {
    //   const serviceList = [...availableServices];
    //   return serviceList;
    // },

  },
  created() {
    this.setdobMax();
    this.setdobMin();
    this.getServices();
  },

};
</script>

<style scoped>
.signup-form {
  max-width: 700px;
  margin: 10px auto;
  border: 1px solid #eee;
  padding: 10px;
  box-shadow: 0 2px 3px #ccc;
}
.content {
  color: #ffffff;
}
</style>
