<template>
    <v-card width="600px" class="mt-5 mx-auto" color="grey darken-3" >
        <v-card-title class="pb-0">
            <h3 style="color: white;">Enter phone number for account to be deleted</h3>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text >
            <v-form>
                <v-text-field
                label="Enter Phone Number"
                prepend-icon="mdi-cellphone"
                v-model="phone"
                dark
                :error-messages="phoneErrors"
                required
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"/>
                <span
                v-if=" okSignin "
                style="color: #D500F9; font-size:12px">
                <v-icon small color="#D500F9">mdi-information-outline</v-icon>
                Submit to request your GoodayOn account to be deleted.
                </span>
                <v-progress-linear
                    v-if="okSignin"
                    indeterminate
                    rounded
                    height="6"
                    color="purple accent-3">
                </v-progress-linear>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn
            color="white"
            type="submit"
            @click="requestAccountDelete"
            id="sign-in-button"
            :disabled="$v.$invalid">Submit</v-btn>
        </v-card-actions>
    </v-card>
 </template>
 
 <script>
 import { required, maxLength, minLength } from 'vuelidate/lib/validators';
 import { firebaseAuthMixins } from './mixins/firebaseAuth';
 
 export default {
   mixins: [
     firebaseAuthMixins,
   ],
   validations: {
     phone: {
       required,
       // numeric,
       minLen: minLength(13),
       maxLen: maxLength(13),
     },
   },
   data() {
     return {
       // password: '',
       // showPassword: false,
       badgeNumber: null,
       phone: '',
       value: String,
     };
   },
   methods: {
     requestAccountDelete() {
        this.phone = '';
    //    this.$store.dispatch('auth/smsAuthenticate', this.phone);
     },
   },
   computed: {
     phoneErrors() {
       const errors = [];
       if (!this.$v.phone.$dirty) return errors;
       // !this.$v.phone.numeric && errors.push('Phone number can have only numerics')
       // eslint-disable-next-line no-unused-expressions
       !this.$v.phone.required && errors.push('Phone number is required');
       if (!this.$v.phone.minLen || !this.$v.phone.maxLen) errors.push('Phone number should be 13 digits including the + sign');
       return errors;
     },
     okSignin() {
       return !this.$v.$invalid;
     },
     // am() {
     //   // eslint-disable-next-line no-console
     //   // console.log("locale is :", this.$i18n.locale)
     //   return this.$i18n.locale === 'አማ';
     // },
   },
   //   watch: {
   //     user() {
   //       if (this.$store.getters['auth/userId'] && this.badgeNumber) {
   //         this.$store.dispatch('auth/signInRouter', this.badgeNumber);
   //       }
   //     },
   //   },
 
 };
 </script> 